import React from 'react'
import { Parser } from 'html-to-react';

function PpfInner(props) {
    return (
        <div className="">

            {props.data ? <>{Parser().parse(props.data)}</> : ''}

        </div>
    )
}

export default PpfInner