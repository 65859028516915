import React, { Component } from 'react'

import { axiosBase } from '../RootLayout';


export default class WhatWeDoListInner extends Component {
    state = {
        whatwedolist: []
    }
    componentDidMount() {
        axiosBase.get(`get-what-we-dos`)
            .then(res => {
                const whatwedolist = res.data;
                this.setState({ whatwedolist: whatwedolist.data });
            })
            
    }
    render() {
        return (
            <>
                {this.state.whatwedolist.map((element) => {
                    return <ul className="list-unstyled" key={element.id}>
                        <li className='whatwedo-text-list'><a className='px-2 text-black small fw-bold' href="/"> {element.title}</a></li>
                    </ul>

                })}
            </>
        )
    }
}
