import React from 'react';
// import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';



export default function Navbar() {

    return (
        <nav className="navbar navbar-expand-xl bg-white pt-3 fixed-top px-3 ">
            <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                    <img className='logo' src={`/assets/img/logo.png`} alt="" />
                </NavLink>
                <button className="navbar-toggler border-0 shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ height: '100%' }}>
                    <ul className="navbar-nav text-center ms-auto m-3">
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-bold text-black " aria-current="page" to="/"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Home</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/about"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">About Us</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/team"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Our Team</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/work"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Our Work</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/publications"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Publications</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/ppf"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">PPF</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/gallery"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Gallery</span></NavLink></li>
                        <li className="nav-item h-100"><NavLink onClick="preventDefault()" className="nav-link p-3 fw-semibold text-black " to="/contact"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Contact Us</span></NavLink></li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}


// Navbar.propTypes = {
//     title: PropTypes.string.isRequired,
//     contact: PropTypes.string
// }
// Navbar.defaultProps = {
//     title: 'Set title text/logo here',
//     contact: 'Set contact text here'
// }
