import React, { useEffect } from "react";

import Client from './Client'
import Form from './Form'
import Hero from './Hero'
import WhatWeDo from './WhatWeDo'
import GoogleMap from './GoogleMap';
import Banner from "./Banner";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { axiosBase } from "../RootLayout";


export default function Home() {
    // const [contactUsImage, setContactUsImage] = useState(null);

    useEffect(() => {
        if (localStorage.getItem('contact-us-image_image') === null) {
            axiosBase.get(`get-contact-us-image`)
                .then(response => response.data)
                .then((data) => {
                    // setContactUsImage(data);
                    localStorage.setItem('contact-us-image_image', data.data.image);

                });
        }

    }, []);
    useEffect(() => {
        AOS.init({disable: 'mobile'});

    }, [])
    const retContactUsImage = localStorage.getItem('contact-us-image_image');

    return (
        <>
            
            <Hero />
            <section>
                <WhatWeDo />
            </section>
            <section className=" m-0 p-0">
                <Banner />
            </section>
            <section>
                <div className="container-fluid">
                    <div className="row card-group position-relative p-3 m-0 justify-content-center ">
                    {retContactUsImage ? 
                        <div className="card border-0 p-sm-3 p-1 my-auto" data-aos="fade-down">
                            <img src={retContactUsImage} alt="" className='rounded-3' />
                        </div> : ''}
                        <div className="card border-0 p-sm-3 p-1" style={{maxWidth: '70%'}} data-aos="fade-up">
                            <h1 className='p-lg-3 p-md-2 p-1 fw-bold'>Contact Us</h1>
                            <Form />
                        </div>
                    </div>
                </div>
            </section>
            <section className="m-0 p-0" >
                <Client />
                <GoogleMap />
            </section>
        </>

    )

}

