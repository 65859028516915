import React, { Component } from 'react'
import TeamMember from './TeamMember';
import { axiosBase } from '../RootLayout';
import Spinner from './Spinner';


export default class Team extends Component {
    constructor(){
        super();
        this.state ={
            team: [],
            loading: false
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        axiosBase.get(`get-teams`, { onDownloadProgress: this.setState({ loading: true }) })
      .then(res => {
        const team = res.data;
        this.setState({ team: team.data });
        if (this.state.team !== null) { this.setState({ loading: false }) };
      })
  }

   
    render() {
        return (
            <>
                {this.state.loading && <Spinner />}
                {this.state.team === null || this.state.loading === false ?
                <div className="container-fluid ">
                    <div className='row position-relative banner-top align-items-center'>
                        <img src="assets/img/about1.png" alt="" className='p-0 object-fit-cover' />
                        <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                            <p className='text-center text-white fw-bold fs-1 m-3 p-3'>Teams</p>
                        </div>
                    </div>
                    <div className='container'>
                        <div className="row">
                            <div className="col-12">
                                <div className="row my-5 row-cols-1 row-cols-md-2 row-cols-lg-3   g-4">
                                {this.state.team.map((element) => {
                                    return <div key={element.id}>
                                        <TeamMember title={element.title} designation={element.designation} content={element.content} image={element.image} email={element.email} />
                                    </div>
                                })}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>: ''}
            </>
        )
    }
}

