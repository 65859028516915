import React, { Component } from 'react'
import WhatWeDoListInner from './WhatWeDoListInner'
import AOS from 'aos';
import { axiosBase } from '../RootLayout';


export default class WhatWeDo extends Component {
    state = {
        whatwedo: []
    }
    componentDidMount() {
        axiosBase.get(`get-we-provide-section`)
            .then(res => {
                const whatwedo = res.data;
                this.setState({ whatwedo: whatwedo.data });
            })
        AOS.init({ disable: 'mobile' });

    }


    render() {
        return (
            <div className="container">
                <div className="row whatwedo d-md-flex bg-white p-3 py-md-5">
                    <div className="col-lg-6 col-12 m-auto" data-aos="fade-down" >
                        <p className="fw-bold fs-3 ">{this.state.whatwedo.title}</p>
                        <p className="whatwedo-text fs-5" >{this.state.whatwedo.content}</p>
                        <WhatWeDoListInner />
                    </div>
                    <div className="col-1 "></div>
                    <div className="col-lg-5 col-11 m-auto" data-aos="fade-left" >
                        <div className="position-relative" >
                            {this.state.whatwedo.back_image  ? <img src={this.state.whatwedo.back_image} alt="" className='mw-100' style={{ paddingRight: '30px' }} /> : ""}
                            {this.state.whatwedo.front_image ? <img src={this.state.whatwedo.front_image} alt="" className="whatwedo-smaller-img  top-25 position-absolute rounded-4 border border-3 border-white mw-100" /> : ""}
                            {this.state.whatwedo.image_title ? 
                            <button className="btn btn-primary position-absolute whatwedo-below-img d-flex py-0 m-3 rounded-3 border-0" >
                                <div className="col-md-4 p-0">
                                    <p className="text-white text-center p-0 m-0 whatwedo-itin">{this.state.whatwedo.image_title}</p>
                                </div>
                                <p className="text-white col-md-8 px-1 text-center m-0 whatwedo-itex text-wrap">{this.state.whatwedo.image_subtitle}</p>
                            </button> : ""}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
