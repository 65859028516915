import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Banner() {
    useEffect(() => {
        AOS.init({ disable: 'mobile' });

    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="card banner border-0 rounded-0 h-100 w-100 d-flex justify-content-center col-md bg-image position-relative p-0" data-aos="zoom-in-up">
                        <img src="assets/img/15.jpg" alt="" className='img-fluid' />
                        <div className="position-absolute banner-back h-100 w-100 top-0 bg-dark opacity-50">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}