import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { axiosBase } from '../RootLayout';
import PublicationContent from './PublicationContent';

export default function Publications() {



  return (
    <>
      <div className="container-fluid ">
        <div className='row position-relative banner-top'>
          <img src="assets/img/about1.png" alt="...." className='p-0 object-fit-cover' />
          <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
            <p className='text-center text-white fw-bold fs-1 m-3 p-3'>Publications</p>
          </div>
        </div>

        <div className='container'>
          <PublicationContent />
        </div>
      </div >
    </>
  )
}
