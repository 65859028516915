import React, { Component } from 'react';
import { axiosBase } from '../RootLayout';
import Spinner from './Spinner';


export default class Hero extends Component {
  constructor() {
    super();
    this.state = {
      hero: [],
      loading: false
    }

  }
  componentDidMount() {
    // console.log(this.state.hero)
    axiosBase.get(`get-home-banner`, { onDownloadProgress: this.setState({ loading: true }) })
      .then(res => {
        this.setState({ hero: res.data.data });
        if (this.state.hero !== null) { this.setState({ loading: false }) };

      });
  }


  render() {
    return (
      <>
        <div className="container-fluid p-0 position-relative">
          <div className="card hero rounded-0 border-0 m-0 p-0" >
            {this.state.loading && <Spinner />}
            <img src={this.state.hero.image} alt="" className='h-100' />
            <div className="position-absolute p-3 d-none d-sm-block hero-text">
              <h2 className='text-white'>{this.state.hero.headings}</h2>
              <h1 className='text-primary'><u>{this.state.hero.sub_title}</u></h1>
            </div>
            <div className="position-absolute p-3 d-sm-none hero-text-small" >
              <h6 className='text-white'>{this.state.hero.headings}</h6>
              <h5 className='text-primary'><u>{this.state.hero.sub_title}</u></h5>
            </div>
          </div>
        </div>
      </>
    )
  }
}
