import React, { Component } from 'react'

import GoogleMap from './GoogleMap';
import Form from './Form';
import { axiosBase } from '../RootLayout';
import Spinner from './Spinner';

export default class Contact extends Component {
    constructor(){
        super();
    this.state = {
        contact: [],
        loading: false
    }
}
    componentDidMount() {
        this.setState({ loading: true });
        axiosBase.get(`get-settings`, { onDownloadProgress: this.setState({ loading: true }) })
            .then(res => {
                const contact = res.data;
                this.setState({ contact: contact.data });
                if (this.state.contact !== null) { this.setState({ loading: false }) };
            });
    }

    render() {
        return (
            <>
                {this.state.loading && <Spinner />}
                {this.state.contact === null || this.state.loading === false ?
                <div className="container-fluid ">
                    <div className='row position-relative banner-top'>
                        <img src="assets/img/about1.png" alt="" className='p-0 object-fit-cover' />
                        <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                            <p className='text-center text-white fw-bold fs-1 m-3 p-3'>Contact</p>
                        </div>
                    </div>

                    <div className='container'>
                        <div className="row">
                            <div className="card border-0 m-auto py-5" >
                                <div className="row g-0">
                                    <div className="col-md-4 contact-aside p-lg-3 p-sm-2 p-0 rounded-3 my-auto " >
                                        <div className="card bg-transparent border-0" >
                                            <div className="row g-0">
                                                <div className="col-md-3 my-3 d-flex justify-content-center">
                                                    <i className="bi bi-envelope text-primary fs-3 fs-lg-1"></i>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body text-center">
                                                        <h6 className="card-title text-primary">Email</h6>
                                                        <p className='text-center fs-6 fs-lg-5'>{this.state.contact.contact_email}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card bg-transparent border-0" >
                                            <div className="row g-0">
                                                <div className="col-md-3 my-3 d-flex justify-content-center">
                                                    <i className="bi bi-building text-primary fs-3 fs-lg-1"></i>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body text-center">
                                                        <h6 className="card-title text-primary">Corporate Office</h6>
                                                        <p className='text-center fs-6 fs-lg-5 text-wrap'>{this.state.contact.corporate_office_location}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card bg-transparent border-0" >
                                            <div className="row g-0">
                                                <div className="col-md-3 my-3 d-flex justify-content-center">
                                                    <i className="bi bi-building-fill-gear text-primary fs-3 fs-lg-1"></i>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="card-body text-center">
                                                        <h6 className="card-title text-primary">City Office</h6>
                                                        <p className='text-center fs-6 fs-lg-5'>{this.state.contact.city_project_office_location}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8" >
                                        <div className="card-body">
                                            <h1 className='p-3 text-nowrap'>Get In Touch</h1>
                                            <Form />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>: ''}
                {this.state.contact === null || this.state.loading === false ? <GoogleMap />: ''}
            </>
        )
    }
}
