import React, { useState } from 'react'
import { Parser } from 'html-to-react';
import { Modal } from "react-bootstrap";



export function TeamMember(props) {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

    return (
      <>
        {/* Team member 1 */}
        <div className="card m-3 " >
          <div className="overflow-hidden">
            <img src={props.image} className=" team-main-img" onClick={handleShow}  alt="..."  />
          </div>
          <div className="card-body text-center">
            <h5 className="card-title user-select-none">{props.title}</h5>
            <p className="card-text user-select-none text-center">{props.designation}</p>
          </div>
        </div>
        {/* modal react-bootstrap */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          <div className="row team-main-1  p-3">
                  <div className="col-md-3">
                    <img src={props.image} className="w-100 rounded-3 " alt="..." />
                  </div>
                  <div className="col-md-9">
                    <h4 className='fw-bold user-select-none'>{props.title}</h4>
                    <span className="text-justify user-select-none">{Parser().parse(props.content)}</span>
                    <p>Email : <a href={`mailTo:${props.email}`}>{props.email}</a></p>
                  </div>
                </div>
          </Modal.Body>
        </Modal>
      </>
    )
  
}


export default TeamMember