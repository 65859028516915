import React from "react";
import { Link } from "react-router-dom";
function PublicationContentItem(props) {
  const { item } = props;
  return (
    <div>
      <div className="publication-items-inner">
        {/* <div className="d-flex align-items-center justify-content-between"> */}
          
          <embed
            class="pdf"
            src={`https://prakritipragya.com/admin${item.file}`}
            width="250"
            height="150"
          />
          <h6
            className="px-3 d-inline-block text-truncate mb-0 text-black d-none d-md-block"
            style={{ maxWidth: "90vw" }}
          >
            {item.title}
          </h6>
          <h6
            className="px-3 d-inline-block text-truncate mb-0 text-black d-md-none"
            style={{ maxWidth: "90vw" }}
          >
            <abbr title={item.title}>{item.title}</abbr>
          </h6>
          <Link
            to={`https://prakritipragya.com/admin${item.file}`}
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="bi bi-download fs-4 publication-items-inner-icon position-relative">
              <div className="position-absolute top-0 start-100 bg-dark text-white p-2 fs-6 rounded-2 publication-items-inner-icon-tooltip mx-3">
                <span className="small text-nowrap">Download</span>
              </div>
            </i>
          </Link>
        {/* </div> */}
      </div>
    </div>
  );
}
export default PublicationContentItem;
