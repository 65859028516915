import React, { useEffect, useState } from 'react'
import { axiosBase } from '../RootLayout';
import { useParams } from 'react-router-dom';



export default function Gallery() {
    const { id } = useParams();
    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [gi, setGallery] = useState([0]);
    // eslint-disable-next-line
    useEffect(() => {
        axiosBase.get(`get-gallery-images/${id}`)
            .then(response => response.data)
            .then((response) => setGallery(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);// eslint-disable-next-line


    const handleOpenModal = (i) => {
        document.body.style.overflow = 'hidden';
        setSlideNumber(i);
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        document.body.style.overflow = 'auto';
    }

    const prev = (i) => {
        slideNumber === 0 ? setSlideNumber(gi.images.length - 1) : (setSlideNumber(slideNumber - 1))
    }

    const nxt = (i) => {
        slideNumber + 1 === gi.images.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1)
    }

    return (
        <div className="container-fluid position-relative" style={{ minHeight: '40vw' }}>
            <div className='row position-relative banner-top'>
                <img src={`/assets/img/about1.png`} alt="" className='p-0 object-fit-cover' />
                <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                    <p className='text-center text-white fw-bold fs-1 m-3 p-3'>{gi.title}</p>
                </div>
            </div>
            <div className={`row g-0 justify-content-center start-0 w-100 h-100`} style={{ zIndex: openModal ? '1030' : '', top: '0', position: openModal ? 'fixed' : '' }}>


                {openModal &&
                    <div className='py-5 w-100' style={{ background: 'rgb(128 128 128 / 95%)', paddingBottom: '5rem !important' }}>
                        <div className='col-12  d-flex justify-content-center w-100'  >
                            <img src={gi.images[slideNumber]} alt="" className='pb-5 pt-4' style={{ height: '45vw', objectFit: 'contain' }} />
                            <a href='#0' className='position-absolute px-5' style={{ right: '12vw', top: '40px' }}>
                                <i className="bi bi-x-lg fs-1 text-white pe-auto" onClick={handleCloseModal}></i>
                            </a>
                            <a href='#0' className='position-absolute top-50 start-0 mx-5 px-3'>
                                <i className="bi bi-chevron-left fs-1 text-white pe-auto" onClick={prev}></i>
                            </a>
                            <a href='#0' className='position-absolute top-50 end-0 mx-5 px-3'>
                                <i className="bi bi-chevron-right fs-1 text-white pe-auto" onClick={nxt}></i>
                            </a>
                        </div>
                    </div>
                }
            </div>
            <div className="container">
                <div className="gallery row py-5 g-3 justify-content-center position-relative">
                    {gi.images?.map((item, i) => (
                        <a href='#0' className="col-12 col-md-4" key={i} onClick={() => handleOpenModal(i)}>
                            <img src={item} alt="" className='w-100' style={{ height: '300px', objectFit: 'cover' }} />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
