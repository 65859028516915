import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import RootLayout from "./RootLayout";
import About from "./Components/About";
import Home from "./Components/Home";
import ErrorPage from "./Components/error-page";
import Publications from "./Components/Publications";
import Work from "./Components/Work";
import Contact from "./Components/Contact";
import Team from "./Components/Team";

import Gallery from "./Components/Gallery";
import GalleryListing from "./Components/GalleryListing";
import Ppf from "./Components/Ppf";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/about",
        element: <About />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/gallery",
        element: <GalleryListing />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/gallery/:id",
        element: <Gallery />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/team",
        element: <Team />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/ppf",
        element: <Ppf />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/work",
        element: <Work />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/publications",
        element: <Publications />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/contact",
        element: <Contact />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
