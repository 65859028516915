import React, { Component } from 'react'
import Slider from "react-slick";
import ClientItems from "./ClientItems";

import AOS from 'aos';
import 'aos/dist/aos.css';
import { axiosBase } from '../RootLayout';

export default class Client extends Component{
    state = {
        client: []
      }
    componentDidMount() {
        axiosBase.get(`get-clients`)
      .then(res => {
        const client = res.data;
        this.setState({ client: client.data });
      });
      AOS.init({disable: 'mobile'});
  }
    render() {
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <div className="container-fluid">
            <div className="row client p-md-5  p-3">
                <p className='text-center text-white fw-bold fs-1 mb-0 p-3'>Our Clients</p>
                <div className="container" data-aos="zoom-in-left">
                    <div className="row mx-auto">
                        <div className="col mx-auto">
                            <Slider {...settings}>
                            {this.state.client.map((element) => {
                                    return <div key={element.id}>
                                        <ClientItems title={element.title} link={element.link} location={element.location} phone={element.phone} toll_free_no={element.toll_free_no} fax={element.fax} email={element.email} />
                                    </div>
                                })}
                                
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    }
}