import React, { Component } from 'react'
import WorkItems from './WorkItems';
import { axiosBase } from '../RootLayout';
import Spinner from './Spinner';


export default class Team extends Component {
    constructor(){
        super();
    this.state = {
        work: []
    }
}
    componentDidMount() {
        this.setState({ loading: true });
        axiosBase.get(`get-works`, { onDownloadProgress: this.setState({ loading: true }) })
            .then(res => {
                const work = res.data;
                this.setState({ work: work.data });
                if (this.state.work !== null) { this.setState({ loading: false }) };
            })
    }


    render() {
        return (
            <>
                {this.state.loading && <Spinner />}
                {this.state.work === null || this.state.loading === false ?
                <div className="container-fluid ">
                    <div className='row position-relative banner-top'>
                        <img src="assets/img/about1.png" alt="...." className='p-0 object-fit-cover' />
                        <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                            <p className='text-center text-white fw-bold fs-1 m-3 p-3'>Projects</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row my-5 row-cols-1 row-cols-lg-3 row-cols-md-2 g-4">
                        {this.state.work.map((element) => {
                                    return <div key={element.id}>
                                        <WorkItems project_year={element.project_year} title={element.title} content={element.content} link={element.link}/>
                                    </div>
                                })}
                        </div></div>
                </div> :''}
            </>
        )
    }
}