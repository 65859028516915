import React, { useEffect, useState } from 'react'
import { axiosBase } from '../RootLayout';


export default function GalleryListing() {
    const [galleryListing, setGalleryListing] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const Data = galleryListing.gallery_images;
    const recordPerPage = 9;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = Data?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(Data?.length / recordPerPage);
    var numbers = [];
    for (var i = 1; i <= npage; i++) {
        numbers.push(i);
    }
    useEffect(() => {
        axiosBase.get(`get-gallery-images`)
            .then(response => response.data)
            .then((response) => setGalleryListing(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);
    return (
        <div className="container-fluid g-0">
            <div className='row g-0 position-relative banner-top'>
                <img src="assets/img/about1.png" alt="" className='p-0 object-fit-cover' />
                <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                    <p className='text-center text-white fw-bold fs-1 m-3 p-3'>Gallery</p>
                </div>
            </div>
            <div className="container">
                <div className="row gallery g-3 p-5">
                    {records?.map((item, index) => (
                        <div className="col-12 col-sm-6 col-lg-4 gallery-item" key={index}>
                            <div className='position-relative'>
                                <img src={item.image} alt="" className='w-100 rounded-3' style={{ height: '300px', objectFit: 'cover' }} />
                                <div className='gallery-item-overlay position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center' style={{ background: 'rgb(255 255 255 / 50%)' }}>
                                    {/* <a href={`${url}/gallery/${item.id}`} className='text-black fs-4 p-5'>{item.title}</a> */}
                                    <a href={`gallery/${item.id}`} className='text-black fs-4 p-5'>{item.title}</a>
                                </div>
                            </div>
                        </div>

                    ))}
                    <nav aria-label='Page navigation example' className={`${npage > 1 ? '' : 'd-none'}`}>
                        <ul className='pagination justify-content-center'>
                            <li className={`page-item ${npage !== null ? '' : 'd-none'} `}>
                                <button
                                    className={`page-link ${currentPage === 1 ? "disabled" : ""}`}
                                    onClick={prePage}
                                >
                                    Previous
                                </button>
                            </li>
                            {numbers.map((n, i) => (
                                <li
                                    className={`page-item ${currentPage === n ? "active" : ""}`}
                                    key={i}
                                >
                                    <button className='page-link' onClick={() => changeCPage(n)}>
                                        {n}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${npage !== null ? '' : 'd-none'} `}>
                                <button
                                    className={`page-link ${currentPage === npage ? "disabled" : ""}`}
                                    onClick={nextPage}
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>
    )
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1);
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
}
