import React, { Component } from 'react'
import { Parser } from 'html-to-react';


export default class WorkItems extends Component {
    
    render() {
        let { project_year, title, content, link } = this.props;
        return (
            <>
                <div className="col">
                    <div className="card mt-3 rounded-4 work-items" >
                        <div className="card-body ">
                            <a href={link} className='text-black' target='_blank' rel="noreferrer" >
                                <h5 className="card-title fw-bold text-primary">Year : <span className='text-muted'>{project_year}</span></h5>
                                <h6 className="card-text text-primary">{title} <span className='fs-6 small text-muted text-nowrap'>( Partner / Client )</span> </h6>
                                {Parser().parse(content)}
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
