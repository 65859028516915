import React, { useEffect } from "react";
import axios from "axios";
import { Outlet } from 'react-router-dom';
import { ScrollRestoration } from 'react-router-dom';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';




// creating a axios component
export const axiosBase = axios.create({
  baseURL: 'https://prakritipragya.com/admin/api/'
});

export default function RootLayout() {

  //Using localstorage to pass data that is being called more than once
  useEffect(() => {
    if (localStorage.getItem('setting_logo') === null) {
      axiosBase.get(`get-settings`)
        .then(response => response.data)
        .then((data) => {
          // setsetting(data);
          localStorage.setItem('setting_title', data.data.title);
          localStorage.setItem('setting_description', data.data.description);
          localStorage.setItem('setting_logo', data.data.logo);
          localStorage.setItem('setting_contact_number', data.data.contact_number);
          localStorage.setItem('setting_corporate_office_location', data.data.corporate_office_location);
          localStorage.setItem('setting_city_project_office_location', data.data.city_project_office_location);
          localStorage.setItem('setting_contact_email', data.data.contact_email);
          localStorage.setItem('setting_google_map_location', data.data.google_map_location);
          localStorage.setItem('setting_facebook_link', data.data.facebook_link);
          localStorage.setItem('setting_twitter_link', data.data.twitter_link);

        });
    }

  }, []);

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      {/* to avoid default behaviour of scroll */}
      <ScrollRestoration />
    </>
  )
}
// export const url = 'http://localhost:3000/'
// export const url = 'https://prakritipragya.com/'