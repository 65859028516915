import React, { useEffect } from 'react';
import { Parser } from 'html-to-react';
import AOS from 'aos';
import 'aos/dist/aos.css';



export default function GoogleMap() {
    const city_project_office_location = localStorage.getItem('setting_google_map_location');

  useEffect(() => {
    AOS.init({disable: 'mobile'});
  }, [])
    
    return (
        <>
            <div className="container-fluid">
                <div className="row google" data-aos="zoom-in-up">
                    {Parser().parse(city_project_office_location)}
                </div>
            </div>
        </>
    )

}