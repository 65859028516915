import React, { Component } from 'react'
import { Parser } from 'html-to-react';
import Banner from './Banner';
import { axiosBase } from '../RootLayout';
import Spinner from './Spinner';

export default class About extends Component {

    constructor() {
        super();
        this.state = {
            aboutus: [],
            aboutusimage: [],
            loading: false
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        axiosBase.get(`get-about-us`, { onDownloadProgress: this.setState({ loading: true }) })
            .then(res => {
                const aboutus = res.data;
                this.setState({ aboutus: aboutus.data });

            });
        axiosBase.get(`get-about-us-image`, { onDownloadProgress: this.setState({ loading: true }) })
            .then(res => {
                const aboutusimage = res.data;
                this.setState({ aboutusimage: aboutusimage.data });
                if (this.state.aboutus !== null) { this.setState({ loading: false }) };
            });
    }

    render() {
        return (
            <>

                {this.state.loading && <Spinner />}
                {this.state.aboutus === null || this.state.loading === false ?
                    <div className="container-fluid ">
                        <div className='row position-relative banner-top '>
                            <img src="assets/img/about1.png" alt="" className='p-0 object-fit-cover' />
                            <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                                <p className='text-center text-white fw-bold fs-1 m-3 p-3'>About Us</p>
                            </div>
                        </div>

                        <div className="card-group row d-lg-flex w-100 ">
                            <div className='card bg-transparent border-0 w-100 h-100 p-3 about-left position-relative'>
                                <div className=' position-relative about-par w-100 h-100'>
                                    {this.state.aboutusimage.back_image ? <div className="position-absolute position-relative about-image-back-outer"> <img src={this.state.aboutusimage.back_image} alt='About us Back  ' className=' border border-4 border-white about-image-back rounded-4 w-100' /> </div> : ""}
                                    {this.state.aboutusimage.front_image ? <div className='position-absolute about-image-front-outer'> <img src={this.state.aboutusimage.front_image} alt='about front  ' className='border border-4 border-white rounded-4 w-100 ' /> </div> : ""}

                                </div>

                            </div>

                            <div className="card border-0 about-body mx-auto justify-content-center" >
                                {this.state.aboutus
                                    // eslint-disable-next-line array-callback-return
                                    .map((element, index) => {
                                        if (index < 1) {
                                            return <div key={element.id}>
                                                <p className='fs-3'>{element.title}</p>
                                                {Parser().parse(element.content)}
                                            </div>
                                        }

                                    })}
                                {this.state.aboutus
                                    // eslint-disable-next-line array-callback-return
                                    .map((element, index) => {
                                        if (index >= 1) {
                                            return <ul className=" p-0" key={element.id}>
                                                <li className='col about-body-list'><p className='fs-3 mx-4'>{element.title}</p> {Parser().parse(element.content)}</li>
                                            </ul>
                                        }

                                    })}
                            </div>
                        </div>
                    </div> : ''}
                {this.state.aboutus === null || this.state.loading === false ? <Banner /> : ''}

            </>
        )
    }
}