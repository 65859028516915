import React, { useState } from 'react'

import { axiosBase } from '../RootLayout';

export default function Form() {
    const [name, createName] = useState('')
    const [email, createEmail] = useState('')
    const [subject, createSubject] = useState('')
    const [message, createMessage] = useState('')
    const onSubmit = async (e) => {
        e.preventDefault()
        const post = {
            name: name,
            email: email,
            subject: subject,
            message: message,
        }
        try {

            const res = await axiosBase.post('post-contact-message', post)
            alert(res.data.message);

        } catch (e) {
            alert(e)
        }
    }
    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="form-row d-flex justify-content-between">
                    <div className="form-group col-md-6 p-lg-3 p-md-2 p-1">
                        <input type="name" className="form-control" id="inputName" placeholder="Your Name" required onChange={(event) => { createName(event.target.value) }} value={name} />
                    </div>
                    <div className="form-group col-md-6 p-lg-3 p-md-2 p-1">
                        <input type="email" className="form-control" id="inputEmail4" placeholder="Email" required onChange={(event) => { createEmail(event.target.value) }} />
                    </div>
                </div>
                <div className="form-group p-lg-3 p-md-2 p-1">
                    <input type="text" className="form-control" id="inputSubject" placeholder="Subject" required onChange={(event) => { createSubject(event.target.value) }} />
                </div>
                <div className="form-group p-lg-3 p-md-2 p-1">
                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Type Your Message' required onChange={(event) => { createMessage(event.target.value) }}></textarea>
                </div>
                <div className="form-group p-lg-3 p-md-2 p-1">
                    <button type="submit" className="btn btn-primary text-white fw-bold border-0 px-4 rounded-3">Submit Message</button>
                </div>
            </form>
        </>
    )

}
