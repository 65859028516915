import React from 'react'

export default function Spinner() {
    return (
        <>
            <div className="container p-5 bg-white d-flex justify-content-center align-items-md-center" style={{height: '100vh'}}>
                <div className="row  p-5">
                    <div className="text-center p-5">
                        <div className="spinner-border text-black p-5" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}