import React, { Component } from 'react'


export default class ClientItems extends Component {
    render() {
        let { title, link, location, phone, toll_free_no, fax, email } = this.props;
        return (

            <div className='d-md-flex justify-content-evenly  p-1 my-lg-5 my-md-3 my-1'>
                <div className='text-center col-12'>
                    <a className="text-black text-center" href={link}>
                        <span className='text-center'>
                        <h5>{title}</h5>
                        {location != null ? <p className='text-center p-0 m-0'>{location} <br /></p> : ''}
                        {phone != null ? <p className='text-center p-0 m-0'>Phone: +{phone}  <br /></p> : ''}
                        {fax != null ? <p className='text-center p-0 m-0'>Fax: +{fax} <br /> </p> : ''}
                        {toll_free_no != null ? <p className='text-center p-0 m-0'>Toll Free No.: {toll_free_no} <br /></p> : ''}
                        {email != null ? <p className='text-center p-0 m-0'>Email : {email}</p> : ''}
                        </span>
                    </a>
                </div>
            </div>

        )
    }
}