import React from 'react';
import PropTypes from 'prop-types'

export default function Footer() {

    const city_project_office_location = localStorage.getItem('setting_city_project_office_location');
    const facebook_link = localStorage.getItem('setting_facebook_link');
    const twitter_link = localStorage.getItem('setting_twitter_link');
    const contact_email = localStorage.getItem('setting_contact_email');
    const description = localStorage.getItem('setting_description');

    return (
        <>

            <div className="container-fluid bg-dark">
                <div className='row card-group text-white p-md-5 mx-md-5 p-3'>
                    <div className="col-md-6">
                        <h5 className='pb-3'>ABOUT</h5>
                        <p className='small p-0 m-0'>{description}</p>
                        <div className='d-flex my-4'>
                            <a href={facebook_link}><i className="px-2 fs-5 bi text-white bi-facebook"></i></a>
                            <a href={twitter_link}><i className="px-2 fs-5 bi text-white bi-twitter"></i></a>

                        </div>
                    </div>
                    <div className="col-md-2"></div>

                    <div className="col-md-4">
                        <h5 className='pb-3'>CONTACT</h5>

                        <p className='p-0 m-0'><strong>Prakriti Pragya Nepal Pvt. Ltd.</strong><br />{city_project_office_location} <br /> Bagmati Province, Nepal</p>
                        <p className='py-2'>Email: {contact_email} </p>
                    </div>
                </div>
            </div>
        </>
    )

}
Footer.defaultProps = {
    contact_email: 'abc@example.com',
    city_project_office_location: 'Nepal',
};
Footer.propTypes = {
    contact_email: PropTypes.string,
    city_project_office_location: PropTypes.string,
}
