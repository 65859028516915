import React, { useEffect, useState } from "react";
import { axiosBase } from "../RootLayout";
import PpfInner from "./PpfInner";


function Ppf() {
    const [pageData, setPageData] = useState();
    useEffect(() => {
        axiosBase.get(`get-ppf`)
            .then(response => response.data)
            .then((response) => setPageData(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);// eslint-disable-next-line
    return (
        <div className="container-fluid ">
            <div className='row position-relative banner-top'>
                <img src="assets/img/about1.png" alt="...." className='p-0 object-fit-cover' />
                <div className="position-absolute d-flex w-100 h-100 justify-content-center align-items-center">
                    <p className='text-center text-white fw-bold fs-1 m-3 p-3'>{pageData ? <>{pageData.title}</> : <>PPF</>}</p>
                </div>
            </div>

            <div className='container'>
                <div className="row ppf py-3 p-md-5">
                    {/* {pageData.content ? <>{Parser().parse(pageData.content)}</> : ''} */}
                    {pageData ? <PpfInner data={pageData.content} /> : ''}
                    {/* {pageData.content} */}
                </div>
            </div>
        </div >

    )
}

export default Ppf