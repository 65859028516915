import React, { useEffect, useState } from "react";
import PublicationContentItem from "./PublicationContentItem";
import { axiosBase } from "../RootLayout";
// const categorylist = [
//     // { id: 1, category: "all", value: "All" },
//     { id: 1, category: "articles", value: "Articles" },
//     { id: 2, category: "research", value: "Research Reports" },
//     { id: 3, category: "company", value: "Company Reports" },
//     { id: 4, category: "consulting", value: "Consulting Reports" }
// ];
// No Documents Availabe



function PublicationContent() {
    const [pageData, setPageData] = useState();
    const [filteredPortfolio, setFilteredPortfolio] = useState(pageData);
    const [countArticle, setCountArticle] = useState();
    const [countMeReport, setCountMeReport] = useState();
    const [countCompany, setCountCompany] = useState();
    const [countConsulting, setCountConsulting] = useState();
    const [countResearch, setCountResearch] = useState();
    // eslint-disable-next-line


    useEffect(() => {
        axiosBase.get(`get-publications`)
            .then(response => response.data)
            .then((response) => setPageData(response.data))
            .catch(err => {
                console.log(err.message);
            })

    }, []);// eslint-disable-next-line

    // const [filter, setFilter] = useState("articles");
    // const filteredPortfolio = useMemo(() => {
    //     if (filter === "all") {
    //         return (props.pageData)
    //     } else {
    //         return (props.pageData.filter((item) => item.category.includes(filter)))
    //     }

    // }, [filter]);


    return (<>
        <div className="row publications py-3 p-md-5">
            <div className="d-flex align-items-start">
                <div className="nav flex-column nav-pills col-6 col-md-2 border border-end-0" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    {/* <button className="nav-link rounded-0 active" id="v-pills-all-tab" data-bs-toggle="pill" data-bs-target="#v-pills-all" type="button" role="tab" aria-controls="v-pills-all" aria-selected="true" onClick={() => setFilteredPortfolio(pageData)}>All</button> */}
                    <button className="nav-link rounded-0 active" id="v-pills-mereport-tab" data-bs-toggle="pill" data-bs-target="#v-pills-mereport" type="button" role="tab" aria-controls="v-pills-mereport" aria-selected="false" onClick={() => (setFilteredPortfolio(pageData?.filter((item) => item.category.includes("mereport"))), setCountMeReport(pageData.filter(item => item.category === 'mereport').length))}>M&E Report</button>
                    <button className="nav-link rounded-0" id="v-pills-article-tab" data-bs-toggle="pill" data-bs-target="#v-pills-article" type="button" role="tab" aria-controls="v-pills-article" aria-selected="false" onClick={() => (setFilteredPortfolio(pageData?.filter((item) => item.category.includes("articles"))), setCountArticle(pageData.filter(item => item.category === 'article').length))}>Articles</button>
                    <button className="nav-link rounded-0" id="v-pills-company-tab" data-bs-toggle="pill" data-bs-target="#v-pills-company" type="button" role="tab" aria-controls="v-pills-company" aria-selected="false" onClick={() => setFilteredPortfolio(pageData?.filter((item) => item.category.includes("company")), setCountCompany(pageData.filter(item => item.category === 'company').length))}>Company Reports</button>
                    <button className="nav-link rounded-0" id="v-pills-consulting-tab" data-bs-toggle="pill" data-bs-target="#v-pills-consulting" type="button" role="tab" aria-controls="v-pills-consulting" aria-selected="false" onClick={() => setFilteredPortfolio(pageData?.filter((item) => item.category.includes("consulting")), setCountConsulting(pageData.filter(item => item.category === 'consulting').length))} >Consulting Reports</button>
                    <button className="nav-link rounded-0" id="v-pills-research-tab" data-bs-toggle="pill" data-bs-target="#v-pills-research" type="button" role="tab" aria-controls="v-pills-research" aria-selected="false" onClick={() => setFilteredPortfolio(pageData?.filter((item) => item.category.includes("research")), setCountResearch(pageData.filter(item => item.category === 'research').length))}>Research Reports</button>
                </div>
                <div className="tab-content col-6 col-md-10 border p-md-3" id="pills-tabContent">
                    {/* <div className="tab-pane fade show active" id="v-pills-all" role="tabpanel" aria-labelledby="v-pills-all-tab" tabIndex="0">
                     <div className="row">
                        {filteredPortfolio ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <>
                            {pageData?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </>}
                    </div>
                    </div> */}
                    <div className="tab-pane fade show active" id="v-pills-mereport" role="tabpanel" aria-labelledby="v-pills-mereport-tab" tabIndex="0">
                        <div className="row">
                        {countMeReport !== 0 ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <h5 className="text-black my-md-4 p-5">Coming Soon</h5>}
</div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-article" role="tabpanel" aria-labelledby="v-pills-article-tab" tabIndex="0">
                        <div className="row">
                        {countArticle !== 0 ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <h5 className="text-black my-md-4 p-5">Coming Soon</h5>}
</div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-company" role="tabpanel" aria-labelledby="v-pills-company-tab" tabIndex="0">
                        <div className="row">
                        {countCompany !== 0 ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <h5 className="text-black my-md-4 p-5">Coming Soon</h5>}
                    </div></div>
                    <div className="tab-pane fade" id="v-pills-consulting" role="tabpanel" aria-labelledby="v-pills-consulting-tab" tabIndex="0">
                        <div className="row">
                        {countConsulting !== 0 ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <h5 className="text-black my-md-4 p-5">Coming Soon</h5>}
                    </div></div>
                    <div className="tab-pane fade" id="v-pills-research" role="tabpanel" aria-labelledby="v-pills-research-tab" tabIndex="0">
                        <div className="row">

                        {countResearch !== 0 ? <>
                            {filteredPortfolio?.map((item) => (
                                <div className="m-2 col-lg-3 col-md-4 col-sm-12" key={item.id}>
                                    <PublicationContentItem item={item} />
                                </div>
                            ))}
                        </> : <h5 className="</div>text-black my-md-4 p-5">Coming Soon</h5>}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default PublicationContent;
